/**
 * Nav
 */

// Reboot based on :
// - sanitize.css v13.0.0 | CC0 1.0 Universal | github.com/csstools/sanitize.css

// Prevent VoiceOver from ignoring list semantics in Safari (opinionated)
:where(nav li)::before {
  float: left;
  content: "\200B";
}

// Pico
// ––––––––––––––––––––

// Horizontal Nav
nav,
nav ul {
  display: flex;
}

nav {
  justify-content: space-between;

  ol,
  ul {
    align-items: center;
    margin-bottom: 0;
    padding: 0;
    list-style: none;

    &:first-of-type {
      margin-left: calc(var(--nav-element-spacing-horizontal) * -1);
    }
    &:last-of-type {
      margin-right: calc(var(--nav-element-spacing-horizontal) * -1);
    }
  }

  li {
    display: inline-block;
    margin: 0;
    padding: var(--nav-element-spacing-vertical)
      var(--nav-element-spacing-horizontal);

    // Minimal support for buttons and forms elements
    > * {
      --spacing: 0;
    }
  }

  :where(a, [role="link"]) {
    display: inline-block;
    margin: calc(var(--nav-link-spacing-vertical) * -1)
      calc(var(--nav-link-spacing-horizontal) * -1);
    padding: var(--nav-link-spacing-vertical) var(--nav-link-spacing-horizontal);
    border-radius: var(--border-radius);
    text-decoration: none;

    &:is([aria-current], :hover, :active, :focus) {
      text-decoration: none;
    }
  }

  // Breadcrumb
  &[aria-label="breadcrumb"] {
    align-items: center;
    justify-content: start;

    & ul li {
      &:not(:first-child) {
        margin-inline-start: var(--nav-link-spacing-horizontal);
      }

      &:not(:last-child) {
        ::after {
          position: absolute;
          width: calc(var(--nav-link-spacing-horizontal) * 2);
          margin-inline-start: calc(var(--nav-link-spacing-horizontal) / 2);
          content: "/";
          color: var(--muted-color);
          text-align: center;
        }
      }
    }

    & a[aria-current] {
      background-color: transparent;
      color: inherit;
      text-decoration: none;
      pointer-events: none;
    }
  }

  // Minimal support for role="button"
  [role="button"] {
    margin-right: inherit;
    margin-left: inherit;
    padding: var(--nav-link-spacing-vertical) var(--nav-link-spacing-horizontal);
  }
}

// Vertical Nav
aside {
  nav,
  ol,
  ul,
  li {
    display: block;
  }

  li {
    padding: calc(var(--nav-element-spacing-vertical) * 0.5)
      var(--nav-element-spacing-horizontal);

    a {
      display: block;
    }

    // Minimal support for links as buttons
    [role="button"] {
      margin: inherit;
    }
  }
}

// Breadcrumb RTL
[dir="rtl"] {
  nav {
    &[aria-label="breadcrumb"] {
      & ul li {
        &:not(:last-child) {
          ::after {
            content: "\\";
          }
        }
      }
    }
  }
}
