@if ($enable-classes and $enable-grid) {
  /**
 * Grid
 * Minimal grid system with auto-layout columns
 */

  .grid {
    grid-column-gap: var(--grid-spacing-horizontal);
    grid-row-gap: var(--grid-spacing-vertical);
    display: grid;
    grid-template-columns: 1fr;
    margin: 0;

    @if map-get($breakpoints, "lg") {
      @media (min-width: map-get($breakpoints, "lg")) {
        grid-template-columns: repeat(auto-fit, minmax(0%, 1fr));
      }
    }

    & > * {
      min-width: 0; // HACK for childs in overflow
    }
  }
}
