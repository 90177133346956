/**
 * Accordion (<details>)
 */

details {
  display: block;
  margin-bottom: var(--spacing);
  padding-bottom: var(--spacing);
  border-bottom: var(--border-width) solid var(--accordion-border-color);

  summary {
    line-height: 1rem;
    list-style-type: none;
    cursor: pointer;

    &:not([role]) {
      color: var(--accordion-close-summary-color);
    }

    @if $enable-transitions {
      transition: color var(--transition);
    }

    // Reset marker
    &::-webkit-details-marker {
      display: none;
    }

    &::marker {
      display: none;
    }

    &::-moz-list-bullet {
      list-style-type: none;
    }

    // Marker
    &::after {
      display: block;
      width: 1rem;
      height: 1rem;
      margin-inline-start: calc(var(--spacing, 1rem) * 0.5);
      float: right;
      transform: rotate(-90deg);
      background-image: var(--icon-chevron);
      background-position: right center;
      background-size: 1rem auto;
      background-repeat: no-repeat;
      content: "";

      @if $enable-transitions {
        transition: transform var(--transition);
      }
    }

    &:focus {
      outline: none;

      &:not([role="button"]) {
        color: var(--accordion-active-summary-color);
      }
    }

    // Type button
    &[role="button"] {
      width: 100%;
      text-align: left;

      // Marker
      &::after {
        height: calc(1rem * var(--line-height, 1.5));
        background-image: var(--icon-chevron-button);
      }

      @if $enable-classes {
        // .contrast
        &:not(.outline).contrast {
          // Marker
          &::after {
            background-image: var(--icon-chevron-button-inverse);
          }
        }
      }
    }
  }

  // Open
  &[open] {
    > summary {
      margin-bottom: calc(var(--spacing));

      &:not([role]) {
        &:not(:focus) {
          color: var(--accordion-open-summary-color);
        }
      }

      &::after {
        transform: rotate(0);
      }
    }
  }
}

[dir="rtl"] {
  details {
    summary {
      text-align: right;

      &::after {
        float: left;
        background-position: left center;
      }
    }
  }
}
